import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/no-one.jpg";
  const fullName = `Name`;
  const zoomUrl = "";
  const zoomPhoneNumber = "(123) 456-7890";
  const zoomMeetingId = "123 4567 7890";
  const zoomMeetingPasscode = "123456";
  const birthDate = new Date(1980, 1 - 1, 1);
  const deathDate = new Date(2020, 12 - 1, 31);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 31);
  const memorialTime = "2pm EST";
  const inviteText = ``;
  const inviteChild = ``;
  const obituary = `Long line
  \nLine 2
  \nLine 3`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl = "";
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
      dropboxUrl={dropboxUrl}
    >
      <h4>In Lieu of Flowers</h4>
      <p>
        Please donate to{" "}
        <a href="https://afterword.com" target="_blank" rel="noreferrer">
          This Organization
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
